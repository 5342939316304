import { t } from "@lingui/macro";
import { Coupon } from "./coupons";
import { GeoUnit } from "./geounit";
import { Category, Point, Product, ProductImage, Supplier } from "./inventory";
import { DetailUsers, User, UserData } from "./user";
import { Customer } from "./orders";
export interface LeadStatus {
  id: number;
  name: string;
  color: string;
}

export interface LeadStatusV2 {
  value: string;
  label: string;
}

export interface CreatedBySupplierUser {
  user?: User;
  supplier_id: number;
  dni?: string;
}

export enum BudgetStatus {
  ACCEPTED = "accepted",
  PENDING = "pending",
  INVOICED = "invoiced",
  TO_INVOICE = "to_invoice",
}

export interface Budget {
  is_closed?: boolean;
  details: LeadDetail[];
  created: string;
  modified: string;
  po_supplier?: Supplier;
  id: number;
  number: number;
  date?: string;
  kind?: string;
  lead_id?: number;
  status?: BudgetStatus;
  lead: Lead;
  total?: number;
  taxes?: number;
  tax_value?: number;
  created_by_supplier_user?: CreatedBySupplierUser;
  created_by_supplier_user_email?: string;
  terms_and_conditions?: string;
  currency_code?: string;
  discount_value?: number;
  target_supplier?: Supplier;
  additional_tax?: number;
}

export enum PurchaseOrderStatus {
  ACCEPTED = "accepted",
  PENDING = "pending",
  INVOICED = "invoiced",
}

export interface PurchaseOrder {
  details: LeadDetail[];
  target_supplier_id: number;
  po_supplier?: Supplier;
  id: number;
  number: number;
  date?: string;
  created?: string;
  modified: string;
  kind?: string;
  lead_id?: number;
  status?: PurchaseOrderStatus;
  lead: Lead;
  total?: number;
  created_by_supplier_user?: CreatedBySupplierUser;
  created_by_supplier_user_email?: string;
  payment_info?: string;
  currency_code?: string;
  discount_value?: number;
  target_supplier?: Supplier;
  tax_retention?: number;
  additional_tax?: number;
  terms_and_conditions?: string;
  taxes?: number;
  tax_retention_value?: number;
  tax_value?: number;
}

export interface BudgetStatusUpdate {
  id: number;
  status: string;
}
export interface ResumeDetail {
  price: number;
  quantity: number;
}
export interface ResumeRequest {
  discount_value?: number;
  additional_tax?: number;
  details: ResumeDetail[];
  tax_retention?: number;
}

export interface Resume {
  sub_total: number;
  total: number;
  taxes: number;
  tax_value: number;
}

export interface KanbanColumns {
  [name: string]: KanbanCol;
}
export interface KanbanTasks {
  [name: string]: LeadRequestV2;
}

export interface KanbanCol {
  color: string;
  name: string;
  description: string;
  id: number;
  is_default: boolean;
  leads: LeadRequestV2[];
  taskIds: string[];
}

export interface KanbanData {
  columnOrder: string[];
  columns: KanbanColumns;
  tasks: KanbanTasks;
}

interface AttentionHour {
  start_time: string;
  end_time: string;
}

export interface Budgets {
  customer: Budget[];
  supplier: Budget[];
}

export interface LeadRequestV2 {
  tasks_count?: number;
  pending_tasks_count?: number;
  currency_code?: string;
  is_customer_interested?: boolean;
  has_automatic_budget?: boolean;
  total_price?: number;
  assigned_to_supplier_users?: UserData[];
  kind?: string;
  created?: string;
  id?: number;
  assigned_to_supplier_user_ids?: number[];
  phone?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  organization_name?: string;
  organization_dni?: string;
  geo_unit_id?: number;
  status_id?: number;
  status_reason?: string;
  details?: LeadDetail[];
  channel?: string;
  position?: number;
  is_archived?: boolean;
  geo_unit?: GeoUnit;
  new_status?: string;
  is_finished?: boolean;
  customer_id?: number;
}

export interface ServiceArea {
  id: number;
  name: string;
}

export interface Lead {
  is_customer_interested?: boolean;
  has_automatic_budget?: boolean;
  what_customer_wants: string;
  discount_tax_percent?: number;
  payment_info?: string;
  assigned_to_supplier_user_ids?: number[];
  assigned_to_supplier_users?: DetailUsers[];
  discount_value?: number;
  id?: number;
  email?: string;
  first_name?: string;
  tasks_count?: number;
  pending_tasks_count?: number;
  last_name?: string;
  organization_name?: string;
  organization_dni?: string;
  geo_unit_id?: number;
  geo_unit?: GeoUnit;
  phone?: string;
  status_id?: number;
  kind?: string;
  status?: LeadStatus | null;
  status_reason?: string | null;
  details?: LeadDetail[];
  lead_items?: LeadItem[];
  terms_and_conditions?: string;
  channel?: string;
  details_count?: number;
  start_date?: string;
  budget_date?: string;
  created?: string;
  budgets_ids?: number[];
  supplier_id?: number;
  budgets?: Budgets;
  supplier_attention_date?: string;
  supplier_attention_hour?: AttentionHour;
  currency_code?: string;
  coupon_code?: String;
  coupon?: Coupon | null;
  estimated_days?: number;
  updated_by_supplier_user?: any;
  modified?: string;
  total_price?: string | number;
  is_archived: boolean;
  lease_number?: number;
  new_status?: string;
  service_area?: ServiceArea;
  tags?: SupplierTag[];
  customer?: Customer;
}

export interface LeadAttachment {
  id?: number;
  name?: string;
  url: string;
  file?: File;
  date?: string;
  number?: string;
  total?: number;
  modified?: string;
}

export interface LeadDetail {
  is_supplier_search_active?: boolean;
  frontKey?: string;
  quantity?: number;
  description?: string;
  id?: number;
  category_id?: number;
  category_error?: string;
  category: Category | null;
  start_date: string;
  end_date?: string;
  period_rate: string;
  days: number;
  price: number;
  supplier_id: number;
  supplier_price: number;
  transportation_price: number;
  includes_field_training: boolean;
  includes_technical_support: boolean;
  budget_text: string;
  supplier?: Supplier;
  supplier_product?: SupplierProduct;
}

interface SupplierProduct {
  id?: number;
  serial_number?: string;
  name: string;
  product: Product;
}

export interface ShortLeadDetail {
  id?: number;
  category_id: number;
  start_date: string;
  days: number;
}

export interface Recommendation {
  supplier: Supplier;
  daily_price: number;
  weekly_price: number;
  monthly_price: number;
  distance_grade: string;
  distance: number;
}

export interface IMap {
  [name: string]: any;
}

export interface LeadFilters extends IMap {
  kind?: string[];
  categories?: number[] | null;
  budget_author?: number | null;
  sort_by?: string | null;
  has_automatic_budget?: string | null;
  has_automatic_search?: boolean | string | null;
  tags?: number[] | null;
}

export interface DocsFilters extends IMap {
  kind?: string;
  categories?: number[] | null;
  budget_author?: number | null;
  search?: string;
  is_lease?: boolean;
  is_sale?: boolean;
  start_date?: string;
  end_date?: string;
}

export interface BinnacleComment {
  text: string;
  id?: number;
  comment_kind?: string;
  object_id?: number;
  supplier_user?: {
    user: User;
  };
  is_system?: boolean;
  created?: string;
  modified?: string;
}

export interface BudgetToInvoice {
  file?: File | null;
  number?: string | null;
  fileRemote?: string | null;
  total_price?: number | null;
}

export enum InvoicesStatus {
  PAID = "paid",
  PENDING = "pending",
  PARTIAL = "partial",
}

export interface BudgetInvoice {
  id?: number | null;
  file?: string | null;
  number?: string | null;
  budget: Budget;
  total_price: number;
  created: string;
  modified: string;
  status: InvoicesStatus;
}

export interface BudgetInvoiceStatusUpdate {
  id: number;
  status: string;
}

export interface PurchaseOrderInvoice {
  id?: number | null;
  file?: string | null;
  number?: string | null;
  purchase_order: PurchaseOrder;
  total_price: number;
  created: string;
  modified: string;
  status: InvoicesStatus;
}

export interface PurchaseOrderToInvoice {
  file?: File | null;
  number?: string | null;
  fileRemote?: string | null;
  total_price?: number | null;
}

export interface ValidationError {
  field: string;
  message: string;
}

export enum LeadIndustry {
  CONSTRUCTION = "construction",
  INDUSTRIAL = "industrial",
}

export enum TasksFilter {
  NO_TASKS = "no_tasks",
  WITH_TASKS = "with_tasks",
  COMPLETED = "completed",
  PENDING = "pending",
}

export interface BudgetCondition {
  parent_geo_unit: GeoUnit;
  id: number;
  days_of_delay: number;
  daily_price: number;
  weekly_price: number;
  monthly_price: number;
  transportation_price: number;
  category?: Category;
  category_id: number;
  geo_units: GeoUnit[];
  parent_geo_unit_id?: number;
  is_active: boolean;
}

export interface GeoBudgetCondition {
  point?: {
    lat?: number;
    lng?: number;
  };
  categories?: Category[];
  id?: number;
  radius?: number;
  threshold?: number;
  km_price?: number;
  days_of_delay?: number;
  daily_price?: number;
  weekly_price?: number;
  monthly_price?: number;
  transportation_price?: number;
  categories_ids?: number[];
  is_active: boolean;
}
export interface SupplierFee {
  id: number;
  daily_price: number;
  weekly_price: number;
  monthly_price: number;
  transportation_price: number;
  category?: Category;
  category_id: number;
  geo_unit_ids?: number;
  geo_units: GeoUnit[];
  supplier_id: number;
  supplier?: Supplier;
}
export interface SupplierFeePayload {
  daily_price?: number;
  weekly_price?: number;
  monthly_price?: number;
  transportation_price?: number;
  category?: Category;
  category_id?: number;
  geo_unit_ids?: number[];
  geo_units?: GeoUnit[];
  supplier_id?: number;
  supplier?: Supplier;
}

export interface BudgetConditionPayload {
  is_active?: boolean;
  parent_geo_unit_id?: number;
  category_id?: number;
  parent_category_id?: number;
  days_of_delay?: number;
  daily_price?: number;
  weekly_price?: number;
  monthly_price?: number;
  transportation_price?: number;
  geo_unit_ids?: number[];
}

export interface SupplierSearch {
  id: number;
  created: string;
  status: string;
  supplier: Supplier;
  supplier_user: any;
  task_id: number;
}

export const getLeadStatuses = [
  { name: "all", value: t`All` },
  { name: "received", value: t`Received` },
  { name: "contacted", value: t`Contacted` },
  { name: "budgeted", value: t`Budgeted` },
  { name: "accepted", value: t`Accepted` },
  { name: "active", value: t`Active` },
  { name: "finished", value: t`Finished` },
  { name: "returned", value: t`Returned` },
  { name: "lost", value: t`Lost` },
];

export interface SupplierTag {
  id: number;
  name: string;
}

export interface LeadResume {
  profits: number;
  incomes: number;
  expenses: number;
  products: number;
  rental_days: number;
  rental_periods: number;
  bug_report: number;
}

export enum LeadDetailPeriodKind {
  BASE = "base",
  EXTENSION = "extension",
  OTHER = "other",
}

export interface LeadItemPeriod {
  is_closed?: boolean;
  item_id?: string;
  id?: number;
  period_kind?: string;
  start_date?: string;
  end_date?: string;
  period_date?: string | null;
  position_number?: number;
}
export interface LeadItem {
  id?: number;
  uuid?: string;
  category: Category;
  supplier: Supplier;
  supplier_product: SupplierProduct;
  is_finished: boolean;
  details: LeadItemPeriod[];
  status_value?: string;
}

export interface LeadDetailPayload {
  is_finished?: boolean;
  id?: number;
  category_id?: number;
  start_date?: string;
  end_date?: string;
  period_rate?: string;
  period_kind?: LeadDetailPeriodKind;
  days?: number;
  price?: number;
  supplier_id?: number | null;
  supplier_product_id?: number | null;
  supplier_price?: number;
  supplier_product?: Product;
  transportation_price?: number;
  unit?: string | null;
  category?: Category;
  uuid?: string;
  supplier?: Supplier;
  has_budgets_exception?: boolean;
  has_purchase_orders_exception?: boolean;
  lead_item?: LeadItem;
  is_closed?: boolean;
}

export interface LeadDetailGrouped {
  enabled_for_technical_services: boolean;
  id?: string | number;
  uuid: string;
  details: LeadDetailPayload[];
  itemOpen: number;
}

export enum InternalOperationKind {
  MANEUVERS = "maneuvers",
  VARIOUS = "various",
}

export interface InternalOperation {
  id: number;
  name: string;
  kind: InternalOperationKind;
}

export interface BudgetDetailPayload {
  id?: number;
  budget_id?: number;
  purchase_order_id?: number;
  lead_detail_id?: number;
  lead_detail?: LeadDetailPayload;
  category_id?: number;
  internal_operation_id?: number;
  internal_operation?: InternalOperation;
  internal_operation_kind_local?: InternalOperationKind;
  period_rate?: string;
  price?: number;
  quantity?: number;
  supplier_id?: number | null;
  description?: string;
  discount_tax?: number;
  tax_retention?: number;
  tax_retention_mode?: TaxRetentionMode;
  tax_retention_kind?: string;
  category?: Category;
  total_price?: number;
}

export interface BudgetDetailGrouped {
  lead_detail_id: number;
  lead_detail: LeadDetailPayload;
  budget_details: BudgetDetailPayload[];
  budget_details_local: BudgetDetailPayload[];
}

export interface LeadStatusLog {
  status: string;
  checked: boolean;
  date?: string;
}

export interface MovementEvidenceDetail {
  id: number;
  title: string;
  description: string;
  image?: string;
  has_damage: boolean;
  productImage?: ProductImage;
  origin_id?: number;
  canUpdate?: boolean;
}

export interface MovementEvidence {
  id?: number;
  front_image?: string | null;
  back_image?: string | null;
  left_image?: string | null;
  right_image?: string | null;
  details?: MovementEvidenceDetail[];
  person_in_charge?: string;
  conclusion?: string;
}
export interface Movement {
  deletable?: boolean;
  lead_item?: LeadItem;
  lead_detail_uuid?: string;
  uuid?: string;
  images?: ProductImage[];
  supplier_product_description?: string;
  lead_detail_id?: number;
  lead_detail?: LeadDetailPayload;
  id?: number;
  kind: string;
  date: string;
  time?: string;
  category: Category;
  supplier_product_id?: number;
  supplier_product?: Product;
  evidence?: MovementEvidence;
  origin?: string;
  destination?: string;
}

export interface PurchaseOrderDetailGrouped {
  lead_detail_id: number;
  lead_detail: LeadDetailPayload;
  purchase_order_details: BudgetDetailPayload[];
}

export interface TechnicalIssue {
  lead_item?: LeadItem;
  lead_item_uuid?: string;
  lead_detail_uuid?: string;
  id?: number;
  uuid?: string;
  lead_detail_id?: number;
  failure_date: string;
  failure_time?: string;
  failure_description: string;
  technical_date?: string;
  technical_time?: string;
  horometer?: number;
  person_on_charge?: string;
  technical_description?: string;
  technical_conclusion?: string;
  images?: ProductImage[];
  category: Category;
  lead_detail?: LeadDetailPayload;
  supplier_product?: Product;
}

export interface PaymentsCommercialControl {
  id: number;
  number: string;
  lead_id: number;
  object_id: number;
  content_type: string;
  amount: number;
  payment_date: string;
  lead?: Lead;
  attachment?: string;
  invoice_number?: string;
  supplier?: Supplier;
  total?: number;
  content_object?: any;
  credit_notes?: CreditNote[];
  pending_amount?: number;
}
export interface CommercialControlDetail {
  credit_notes_payments: PaymentsCommercialControl[];
  total_with_credit?: number;
  credit_notes: CreditNote[];
  status: string;
  id: number;
  sent: boolean;
  number: string;
  date: string;
  total: number;
  payments: PaymentsCommercialControl[];
  due: number;
  is_paid: boolean;
  email?: string;
  supplier?: Supplier;
  lead?: Lead;
  invoices?: BudgetInvoice[];
}

export interface SummaryCommercialControl {
  details: CommercialControlDetail[];
  due: number;
}

export interface SummaryCommercialControlModule {
  newest_month: string;
  oldest_month: string;
  total: number;
  accepted: number;
  invoiced: number;
  leases: number;
  sales: number;
  paid: number;
  to_pay: number;
}

export interface InvoiceCommercialControl {
  id?: number;
  number: string;
  object_id: number;
  file: any;
}

export enum DocumentKind {
  BUDGET = "budget",
  PURCHASE_ORDER = "purchase_order",
  PAYMENTS = "payments",
  INVOICES = "invoices",
  MOVEMENT = "movement",
  TECHNICAL_SERVICE = "technical_service",
  OTHER_DOCUMENT = "other",
}

export interface Schedule {
  address?: string;
  unit?: string;
  id: number;
  uuid?: string;
  days?: number;
  start_date?: string;
  end_date?: string;
  expiration?: number;
  category?: Category;
  lead?: Lead;
  supplier?: Supplier;
  status?: string;
  lead_id?: number;
  is_finished?: boolean;
  supplier_product_id?: number;
  supplier_product?: Product;
  point?: Point;
}

export interface ScheduleFilter {
  category_id?: number;
  status?: string;
  assigned_to_supplier_user_id?: number;
  supplier_id?: number;
}

export enum TaxRetentionMode {
  PERCENTAGE_OF_TOTAL = "percentage_of_total",
  PERCENTAGE_OF_TAX = "percentage_of_tax",
}

export enum PaymentType {
  BUDGET = "budget",
  PURCHASE_ORDER = "purchaseorder",
}

export enum CommercialControlStatus {
  ACCEPTED = "accepted",
  PENDING = "pending",
  INVOICED = "invoiced",
}

export interface CommercialControlFilter extends IMap {
  status?: string[];
  is_paid?: boolean | null;
}

export interface CreditNoteDetail {
  price: number;
  ref_detail_id: number;
}

export interface CreditNote {
  ref_object_number?: any;
  id?: number;
  content_type: string;
  ref_object_id?: number;
  number: string;
  date: string;
  details: CreditNoteDetail[];
  attachment: any;
  terms_and_conditions?: string;
}
export interface CreditNotesSummaryDetail {
  price: number;
  total: number;
  ref_detail_id: number;
}
export interface CreditNotesSummary {
  sub_total: number;
  total: number;
  taxes: number;
  details: CreditNotesSummaryDetail[];
}

export interface LeadItemPeriod {
  item_id?: string;
  id?: number;
  period_kind?: string;
  start_date?: string;
  end_date?: string;
  period_date?: string | null;
  position_number?: number;
}
export interface LeadItem {
  enabled_for_technical_services?: boolean;
  id?: number;
  uuid?: string;
  category: Category;
  supplier: Supplier;
  supplier_product: SupplierProduct;
  is_finished: boolean;
  details: LeadItemPeriod[];
}

export interface CommercialPeriod {
  newest_month: string;
  oldest_month: string;
}
