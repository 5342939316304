import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";

export enum SortBy {
  ASC = "asc",
  DESC = "desc",
  NONE = "none",
}

export default function ListSortByComponent({
  name,
  sort_by,
  handleSort,
}: {
  name: string;
  sort_by: SortBy;
  handleSort: (sort_by: SortBy) => void;
}) {
  const handleClick = () => {
    switch (sort_by) {
      case SortBy.NONE:
        handleSort(SortBy.ASC);
        break;
      case SortBy.ASC:
        handleSort(SortBy.DESC);
        break;
      case SortBy.DESC:
        handleSort(SortBy.NONE);
    }
  };
  return (
    <>
      <a
        href="#"
        className="flex items-center cursor-pointer cursor-hover"
        onClick={handleClick}
      >
        {name}

        {sort_by !== SortBy.NONE &&
          (sort_by === SortBy.ASC ? (
            <ArrowSmUpIcon className="ml-1" height={15} width={15} />
          ) : (
            <ArrowSmDownIcon className="ml-1" height={15} width={15} />
          ))}
      </a>
    </>
  );
}
