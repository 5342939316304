import { GoPage } from "@/lib/dto/requests";
import { useGetLeadsQuery } from "@/lib/services/leads";
import { Trans, t } from "@lingui/macro";
import { useEffect, useMemo, useState } from "react";
import SkeletonTable from "../Utils/SkeletonTable";
import { Lead, LeadDetail, LeadItem } from "@/lib/dto/leads";
import { TicketIcon } from "@heroicons/react/outline";
import { classNames, moneyFormatCurrency } from "@/lib/utils";
import { useRouter } from "next/router";
import { getClassFromStatusColor } from "@/lib/utils/colorsUtils";
import { getLeadStatusTranslated } from "@/lib/utils/translateUtils";
import Link from "next/link";
import NoTableData from "./Tables/NoTableData";
import { PAGE_SIZE_MY_SPACE } from "@/lib/services/config";
import ListSortByComponent, { SortBy } from "../Lead/ListSortBy";
import _ from "lodash";

interface Props {
  supplier_user_id?: number;
}

export default function MySpaceRequeriments({ supplier_user_id }: Props) {
  const locale = useRouter().locale;
  const statuses = [
    { value: "pending", label: t`Pending / Contacted` },
    { value: "inminent", label: t`inminent_plural` },
    { value: "budgeted", label: t`Budgeted by price` },
  ];
  const [selectedStatus, setSelectedStatus] = useState<{
    value: string;
    label: string;
  }>(statuses[0]);

  const [goPage, setGoPage] = useState<GoPage>({
    page_size: PAGE_SIZE_MY_SPACE,
    filters: {
      new_status: ["received", "contacted"],
    },
  });

  const { data, isLoading, isFetching } = useGetLeadsQuery(goPage, {
    skip: !supplier_user_id,
  });

  const isLoadingData =
    isLoading ||
    !supplier_user_id ||
    (isFetching && data && goPage.page_size === PAGE_SIZE_MY_SPACE);
  const isLoadingNewItem =
    isFetching && data && goPage.page_size !== PAGE_SIZE_MY_SPACE;

  const showPageCount = () => {
    if (data?.count) {
      if (goPage.page_size && data.count < goPage.page_size) {
        return data?.count;
      }
      return goPage.page_size;
    }
    return 0;
  };

  const handleStatusChange = (status: { value: string; label: string }) => {
    setSelectedStatus(status);
    let newFilters = {};
    if (status.value === "pending") {
      newFilters = {
        new_status: ["received", "contacted"],
        assigned_to: supplier_user_id,
      };
    } else if (status.value === "budgeted") {
      newFilters = {
        new_status: ["budgeted"],
        sort_by: "total_price",
        reverse: true,
        assigned_to: supplier_user_id,
      };
    } else {
      newFilters = {
        tag_name: t`Inminent`,
        assigned_to: supplier_user_id,
      };
    }
    setGoPage({
      page_size: 4,
      filters: newFilters,
    });
  };

  useEffect(() => {
    if (supplier_user_id) {
      setGoPage({
        page_size: PAGE_SIZE_MY_SPACE,
        filters: {
          ...goPage.filters,
          assigned_to: supplier_user_id,
        },
      });
    }
  }, [supplier_user_id]);

  const getNoTableDataMessage = () => {
    if (selectedStatus.value === "pending") {
      return t`You don't have any pending / contacted requirements`;
    }
    return t`You don't have any inminent requirements`;
  };

  const sortByRowName = (name: string): SortBy => {
    return goPage.filters?.sort_by === name
      ? goPage.filters?.reverse
        ? SortBy.DESC
        : SortBy.ASC
      : SortBy.NONE;
  };

  const handleSortBy = (name: string, sort: SortBy) => {
    if (sort !== SortBy.NONE) {
      setGoPage({
        ...goPage,
        filters: {
          ...goPage.filters,
          sort_by: name,
          reverse: sort === SortBy.ASC ? false : true,
        },
      });
    } else {
      if (goPage.filters?.sort_by) {
        const copy = _.cloneDeep(goPage);
        delete copy?.filters?.sort_by;
        setGoPage({
          ...copy,
        });
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex-1">
          <h1 className="text-lg font-semibold text-gray-800">{t`Requirements`}</h1>
          <Link href="/leads">
            <a href="#" className="text-primary text-sm underline">
              <Trans>Go to requirements</Trans>
            </a>
          </Link>
        </div>
        <div className="flex-2 flex justify-center">
          <div className="grid grid-cols-3 gap-x-1 bg-blue-200 rounded-md h-10 mt-2 px-1 w-full">
            {statuses.map((status) => (
              <div
                key={`myspace_leads_status_${status.value}`}
                onClick={() => handleStatusChange(status)}
                className={`${
                  status.value === selectedStatus.value
                    ? "bg-white rounded-md shadow hover:shadow-md "
                    : "hover:bg-white hover:rounded-md hover:shadow-md "
                } my-1 px-1 text-sm col-span-1 cursor-pointer flex items-center justify-center w-full`}
              >
                {status.label}
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 flex items-center justify-end">
          <div className="text-sm text-gray-600">
            {t`Showing`}
            <span className="mx-1">
              {showPageCount()} {t`of`} {data?.count ?? 0}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-2 overflow-hidden border border-gray-400 shadow sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-100">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                scope="col"
                className="pl-3 py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                ID
              </th>
              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <Trans>Client</Trans>
              </th>
              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <ListSortByComponent
                  name={t`Total amount`}
                  sort_by={sortByRowName("total_price")}
                  handleSort={(sort_by: SortBy) =>
                    handleSortBy("total_price", sort_by)
                  }
                />
              </th>
              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <Trans>Items</Trans>
              </th>

              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <Trans>Status</Trans>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoadingData && (
              <SkeletonTable colSpan={5} length={(goPage.page_size ?? 1) + 1} />
            )}
            {!isLoadingData &&
              data?.results.map((lead: Lead) => (
                <tr key={`myspace_leads_${lead.id}`}>
                  <td className="pl-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <a
                      className="underline text-primary"
                      href={`/leads/${lead.id}/new-detail`}
                    >
                      {lead.id}
                    </a>
                  </td>
                  <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                    <td>
                      <div className="flex items-center">
                        {lead.organization_name ? (
                          <div className="">
                            <div className="text-sm flex font-medium text-gray-900 whitespace-normal uppercase">
                              {lead.coupon_code && (
                                <TicketIcon className="h-5 w-5 text-safety-orange mr-2" />
                              )}
                              {lead.organization_name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {lead.organization_dni}
                            </div>
                          </div>
                        ) : (
                          <div className="">
                            <div className="text-sm flex font-medium text-gray-900 whitespace-normal uppercase">
                              {lead.coupon_code && (
                                <TicketIcon className="h-5 w-5 text-safety-orange mr-2" />
                              )}
                              {lead.first_name} {lead.last_name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {lead.phone}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </td>
                  <td className="pl-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    {lead?.total_price ? (
                      <div className="text-blue-crayola font-semibold">
                        {moneyFormatCurrency(
                          Number(lead?.total_price) || 0,
                          locale,
                          lead?.currency_code
                        )}
                      </div>
                    ) : (
                      <>--</>
                    )}
                  </td>
                  <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                    {lead.lead_items?.map((item: LeadItem) => (
                      <div key={`detail_${item.id}`}>{item.category?.name}</div>
                    ))}
                  </td>
                  <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                    <span
                      className={classNames(
                        getClassFromStatusColor(lead.status?.color),
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                      )}
                    >
                      {lead.status?.name &&
                        getLeadStatusTranslated(lead.status?.name)}
                    </span>
                  </td>
                </tr>
              ))}
            {isLoadingNewItem && <SkeletonTable colSpan={5} length={1} />}
          </tbody>
        </table>
        {!isLoadingData && !isLoadingNewItem && data?.results.length === 0 && (
          <NoTableData message={getNoTableDataMessage()} />
        )}
        {!isLoadingData && !isLoadingNewItem && data?.next_page && (
          <div>
            <div className="w-full bg-gray-200 border-t border-gray-200"></div>
            <div className="w-full flex justify-center p-2 bg-white hover:bg-gray-100 cursor-pointer">
              <button
                onClick={() => {
                  setGoPage({
                    ...goPage,
                    page_size: (goPage.page_size ?? 1) + 1,
                  });
                }}
                className="text-primary"
              >
                <Trans>Show more</Trans>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
